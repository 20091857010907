/* eslint-disable quotes */
/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useRef } from 'react'
import {
  HomeWrapper,
  HeroWrapper,
  MissionWrapper,
  ServicesWrapper,
  HowToBookWrapper, 
  LocationsWrapper,
  RatingsWrapper
} from '../components/home/styles'
import OurBeautyPros from '../components/home/OurBeautyPros'
import { Text, Header1, Header2, Header3 } from '../styles/Components'
import Button from '../components/Button'
import {
  popularServices as popularServicesFn
} from '../components/home/data'
import { createBugsnagErrorBoundary } from '../helpers'
import { gsColors as c } from '../styles/Variables'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore from 'swiper'
import { Navigation, Autoplay, Pagination } from 'swiper/modules'
import Head from 'next/head'
import { useIsDesktop } from '../helpers/hooks'
import { useRouter } from 'next/router'
import 'swiper/css/bundle'

const ServicesBugsnagErrorBoundary = createBugsnagErrorBoundary('Quotes')
const QuotesBugsnagErrorBoundary = createBugsnagErrorBoundary('Quotes')

const howToBookSteps = ['Input your address', 'Choose your services', 'Select a date and time', 'Create your account', 'Get glam']

export const locations = [
  'New York City',
  'Jersey City', 
  'Los Angeles', 
  'Orange County', 
  'Miami', 
  'Fort Lauderdale', 
  'Washington DC', 
  'Boston', 
  'Long Island', 
  'SF/Bay Area', 
  'Dallas/Fort Worth', 
  'Houston',
  'Las Vegas',
  'Chicago',
  'Nashville',
]

const ratings = [
  {
    quote:
      '“They matched my skin tone, placed extensions expertly, and I have never looked better! They were so fun to hang out with too!”',
    name: 'Shailly P.',
  },
  {
    quote:
      '“Incredible service — both the blowout and the makeup application were perfection. Highly recommend!”',
    name: 'Jen B.',
  },
  {
    quote:
      '“I use Glamsquad for every event! They are in and out in an hour, friendly, professional, and always make me feel wonderful.”',
    name: 'Sara F.',
  },
  {
    quote:
      `“LOVED my hair and makeup! The best it's ever looked! Got so many compliments!!”`,
    name: 'Maddy M.',
  },
  {
    quote:
      '“My hairstylist was on time, professional, and did an excellent job. I had two events back to back (different days) and the style lasted for BOTH!”',
    name: 'Summer L.',
  },
  {
    quote:
      '“Hair and makeup looked gorgeous! Loved how they came right to my apartment — made the getting ready process enjoyable.”',
    name: 'Kate B.',
  },
]


SwiperCore.use([Pagination, Autoplay, Navigation])

const Home = () => {
  const router = useRouter()
  const isDesktop = useIsDesktop()
  const videoPlayer = useRef(null)

  const redirectToBook = ({ serviceType, name }) =>
    router.push(`/book/?stp=${serviceType}&svc=${name.toLowerCase()}`)

  const popularServices = popularServicesFn(redirectToBook)

  useEffect(() => {
    if (!videoPlayer.current) {
      return
    }
    videoPlayer.current.src = isDesktop 
      ? 'https://images.glamsquad.com/client/www/hero-video-desktop.mp4' 
      : 'https://images.glamsquad.com/client/www/hero-video-mobile.mp4'
  }, [isDesktop, videoPlayer])

  return (
    <HomeWrapper>
      <Head>
        <title>Glamsquad</title>
      </Head>

      <HeroWrapper>
        <video
          ref={videoPlayer}
          src='https://images.glamsquad.com/client/www/hero-video-mobile.mp4'
          autoPlay
          muted
          loop
          playsInline
          poster='/home/hero-video-first-frame.jpg'
        />
        <img src='/home/new-logo.svg' width="351" height="48.195" alt='Glamsquad Logo' className='gs-logo'/>
        <Header1
          white
          margin='28px 0'
        >
          BEAUTY ON CALL
        </Header1>
        <Button
          large
          hover
          path='book'
        >
          Book Now
        </Button>
      </HeroWrapper>

      <MissionWrapper>
        <div className='mission-inner'>
          <Text
            variant='large'
          >
            We&apos;re on a mission to eliminate bad hair days, chipped nails, and smokey-eye fails by bringing certified and talented hair stylists, makeup artists, and nail pros right to your door.
          </Text>
        </div>
      </MissionWrapper>
      <ServicesWrapper>
        <Header2 margin='0 0 24px 0'>
          Five-star beauty services
        </Header2>
        <div className='web-carousel-container'>
          {popularServices.map((s, i) => (
            <div 
              key={i} 
              className='carousel-service-card' 
              onClick={s.clickHandler} 
              role='button'
            >
              <div className='card-inner'>
                <Header3 margin='0 0 12px 0'>
                  {s.title}
                </Header3>
                <Text margin='0 0 8px 0'>
                  Starting at ${s.price}
                </Text>
                <Text margin='0 0 44px 0'>
                  {s.description}
                </Text>
              </div>
              {s.image()}
            </div>
          ))}
        </div>
        <div className='mobile-carousel-container'>
          <ServicesBugsnagErrorBoundary>
            <Swiper
              spaceBetween={47}
              slidesPerView={1.3}
              pagination={{ clickable: true, dynamicBullets: false }}
            >
              {popularServices.map((s, i) => (
                <SwiperSlide key={i}>
                  <div 
                    className='carousel-service-card' 
                    onClick={s.clickHandler} 
                    role='button'
                  >
                    <div>
                      <Header3 margin='0 0 12px 0'>
                        {s.title}
                      </Header3>
                      <Text margin='0 0 8px 0'>
                        Starting at ${s.price}
                      </Text>
                      <Text margin='0 0 44px 0'>
                        {s.description}
                      </Text>
                    </div>
                    {s.image()}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </ServicesBugsnagErrorBoundary>
        </div>
      </ServicesWrapper>
      <HowToBookWrapper>
        <div className='mobile-container'>
          <Header2 margin='0 0 24px 0'>
            How to book glam
          </Header2>
          <div className='how-to-step-container'>
            {howToBookSteps.map((s, i) => (
              <div className='how-to-step' key={i}>
                <Header3
                  margin='0 8px 0 0'
                  color={c.cobalt}
                >
                  0{i + 1}
                </Header3>
                <Text
                  variant='medium'
                >
                  {s}
                </Text>
              </div>
            ))}
          </div>
          <Button large outline color='#000' path='book'>
            Get Started
          </Button>
        </div>
      </HowToBookWrapper>
      <OurBeautyPros />
      <LocationsWrapper>
        <Header3
          margin='0 0 18px 0'
        >
          Our squad currently serves
        </Header3>
        <div className='location-list'>
          {locations.map((l, i, a) => (
            <React.Fragment key={i}>
              <Text
                whiteSpace='nowrap'
                variant='large'
              >
                {l}
              </Text>
              {i !== a.length - 1 && <Text margin='0 20px' variant='large' color={c.salmon}>|</Text>}
            </React.Fragment>
          ))}
        </div>
      </LocationsWrapper>
      <RatingsWrapper>
        <div className='swiper-container-container'>
          <QuotesBugsnagErrorBoundary>
            <Swiper
              modules={[Navigation, Autoplay]}
              slidesPerView={1}
              autoplay={{ delay: 1500, disableOnInteraction: true }}
              navigation={{ nextEl: '.custom-next-button', prevEl: '.custom-prev-button' }}
              className='swiper-container'
            >
              {ratings.map((r, i) => (
                <SwiperSlide key={i}>
                  <div className='rating'>
                    <div className='star-container'>
                      {new Array(5).fill('').map((_, i) => <img key={i} src='/home/star.svg'  width="24" height="24" alt='star' />)}
                    </div>
                    <Header3 color='#fff' align='center' margin='0 0 16px 0'>
                      {r.quote}
                    </Header3>
                    <Header3 color='#fff' align='center'>
                      - {r.name}
                    </Header3>
                  </div>
                </SwiperSlide>
              ))}
              <button className='custom-prev-button' />
              <button className='custom-next-button' />
            </Swiper>
          </QuotesBugsnagErrorBoundary>
        </div>
      </RatingsWrapper>
    </HomeWrapper>
  )
}

export default Home
